.Dashboard {
  width: 100%;
  border: 3px solid $color-border;
  padding: 20px;
  margin: 20px 0;
  @media($mq-from-tablet) {
    margin: 0 0 20px 0;
    padding: 30px;
  }

  .Dashboard-message{
    border: 2px solid #DADBDC;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;

    @media($mq-phone) {
      gap: 12px;
    }
    .check-icon-carnet{
      @media($mq-phone) {
        margin: initial;;
      }
    }
    .icon-mask{
      height: 16px;
      width: 28px;
    }
    .desc{
      width: 552px;
      font-weight: bold;
      text-align: center;
      @media($mq-phone) {
        width: 100%;
        margin-bottom: 13px;
      }
    }
    .desc-request{
      width: 846px;
      font-weight: 700;
      font-size: 16px;
      display: flex;
      flex-direction: column;
      text-align: center;
      max-width: 100%;
    }
    .request-access{
      border: 1px solid #2C2E34;
      padding: 17px 45px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }

  &[aria-hidden=true] {
    display: none;
  }

  .PrimaryButton {
    margin: 10px 0 0 0;
    white-space: nowrap;
  }

  &:has(.creation-form) {
    border: none; // variant for creation page form, comment it if not approved
    box-shadow: 0 2px 18px 0 rgba(141, 141, 141, 0.5); // variant for creation page form, comment it if not approved
    padding: 0;
  }

  form#form_coupon {
    position: relative;

    label {
      .invalid-feedback {
        position: absolute;
        left: 75px;
        top: -1px;

        .form-error-icon.badge.badge-danger {
          display: none;
        }

        .form-error-message {
          color: red;
        }
      }
    }
  }

  #engagement_submit {
    margin: 27px 0 0;
    padding: 10px 45px;

    @media($mq-phone) {
      margin: 0;
    }
  }
}

.Dashboard--empty {
  padding: 0;
  border: none;
}

.Dashboard--frame { // variant used for login details frame
  position: relative;
  margin-top: 50px;
  .Dashboard-title {
    font-size: 0.6rem;
    position: absolute;
    text-align: center;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    background-color: $color-white;

    @media($mq-from-tablet) {
      width: auto;
      font-size: 0.9rem;
    }

    &::before {
      content: '';
      position: absolute;
      left: -15px;
      width: 15px;
      height: 15px;
      background-color: $color-white;
    }
    &::after {
      content: '';
      position: absolute;
      right: -15px;
      width: 15px;
      height: 15px;
      background-color: $color-white;
    }
  }

}

.Dashboard-title {
  @include thermor-title4c;
  * + & {
    margin-top: 40px;
  }
}

.Dashboard-paragraph {
  color: $color-black;
  font-size: 0.9rem;
  line-height: 1.2;
  margin-bottom: 2rem;
}

.Dashboard-infoLine {
  color: $color-black;
  font-size: 0.8rem;
  line-height: 1.69;;
}

.Dashboard-link {
  display: block;
  font-size: 0.8rem;
  line-height: 2.13;
  color: $color-black;
}

.Dashboard-label {
  font-weight: 700;
}

.Dashboard-title--visibility {
  margin-bottom: 24px;
  color: #2C2E34;
}

.Dashboard--noBorder {
  padding: 0;
  border: none;
}

.Dashboard-VisibilityForm {
  textarea#field_message {
    min-height: 170px;
  }

  input.PrimaryButton {
    max-width: none;
    height: auto;
    background-color: transparent;
    border: solid 3px black;
    font-weight: 900;
    font-size: 18px;
    color: black;

    @media($mq-tablet) {
      padding: 17px 78px;
    }
    @media($mq-desktop) {
      padding: 17px 78px;
    }

    &:hover {
      color: #e62814;
      border-color: #e62814;
    }
  }
}

.Dashboard--availability-product {
  border: none;
  padding-top: 0;
  padding-left: 0;

  #form_availability_product {

    h1{
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.15;
      margin-bottom: 25px;
    }

    .row {
      position: relative;
      border: 3px solid #dadbdc;
      padding: 30px 10px;

      @media($mq-phone) {
        margin: 0;
      }

      input.form-control,
      input.form-control::placeholder {
        border-radius: 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.2px;
        color: #A1A3A4;
      }

      input.form-control {
        color: #2C2E34;
      }

      .search-form {
        display: flex;
        justify-content: space-between;

        &-input {
          width: 60%;

          input {
            height: 60px;
          }

          @media($mq-phone) {
            width: 100%;
            margin-bottom: 16px;
          }
        }

        @media($mq-phone) {
          display: block;
        }
      }

      #search_list {
        position: absolute;
        background: #FFFFFF;
        z-index: 999;
        top: 95px;
        left: 25px;
        width: fit-content;
        padding: 15px 40px;
        box-shadow: 0 2px 18px 0 rgba(141,141,141,.5);

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          color: #2C2E34;
          text-transform: uppercase;
          cursor: pointer;
          padding: 10px 0;
          border-bottom: 1px solid #DADBDC;

          .firstThreeLetters {
            font-weight: 900;
          }
        }

        @media($mq-phone) {
          padding: 5px 19px;
        }
      }

      .accordion {
        margin-top: 30px;

        &-header {

            .accordion-button,
            .accordion-button:not(.collapsed) {
              display: flex;
              justify-content: space-between;
              background-color: #f6f6f6;
              box-shadow: none;

              &::after {
                content: none
              }

              p {
                font-size: 16px;
                line-height: 19.2px;
                font-weight: 900;
                color: #2C2E34;
              }

              svg.icon {
                border-radius: 100px;
                padding: 4px;
                width: 28px;
                height: 28px;
                background-color: #A1A3A4;

                &.icon-chevron-down {
                  padding: 8px;
                }
              }
            }


        }

        &-item {
          background-color: #f6f6f6;
          border-radius: 4px;
        }

        &-body {
          ul {
            list-style: disc;
            margin-top: 25px;
            padding: 0 18px;
            font-size: 16px;
            font-weight: 400;
            line-height: 19.2px;
            color: #2C2E34;

            li {
              margin-bottom: 10px;
            }
          }
        }
      }

      button[type=submit] {
        margin-top: 0;

        &[disabled=disabled] {
          background: white;
          color: #A1A3A4;
          border: 3px solid #2c2e3445;
        }
      }
    }
  }

  .results-search {
    text-align: center;
    font-size: 24px;
    font-weight: 300;
    line-height: 32px;
    color: #2C2E34;
    margin-top: 30px;

    &--notice {
      margin-top: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: #A1A3A4;

      @media($mq-phone) {
        margin: 0;
      }
    }
  }

  .table-wrapper {
    border: 3px solid #dadbdc;
    margin-top: 5px;
    padding: 0 20px;
    padding-top: 15px;

    .table-search {
      border-collapse: collapse;
      margin-top: 5px;

        th, td {
          text-align: start;
          padding: 20px 0;
          border-color: #DADBDC;
          border-style: solid;
          border-width: 0;
          border-bottom-width: var(--bs-border-width);
        }

        tbody {

            tr:last-child {

              td {
                border-width: 0;
                padding-bottom: 5px;
              }
            }
        }
    }

    @media($mq-phone) {
      display: none;
    }
  }

  .responsive-table-search {
    display: none;

    .table-search {
      border: 1px solid #DADBDC;
      margin-top: 20px;
      padding: 16px;

      &-row {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        h2 {
          font-size: 16px;
          font-weight: 900;
          line-height: 19.2px;
          color: #2C2E34;
        }

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 19.2px;
          color: #2C2E34;
          margin-top: 10px;
        }
      }
    }

    @media($mq-phone) {
      display: block;
    }
  }

  .message-result {
    h3 {
      text-align: center;
      font-size: 24px;
      font-weight: 300;
      line-height: 32px;
      color: #2C2E34;
      margin-top: 30px;
    }

    .empty-result {
      background: #F7F7F7;
      margin-top: 10px;
      padding: 64px;
      text-align: center;

      p {
        font-size: 24px;
        font-weight: 300;
        line-height: 32px;
        color: #2C2E34;
      }
    }
  }
}

.engagement_coupon.alert {
  margin: -12px 0 0 5px;

  @media ($mq-phone) {
    margin: 10px 0 0 5px;
  }

  &-success {
    color: green;
  }

  &-danger {
    color: #e62814;
  }
}
